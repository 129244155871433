import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { CustomerService } from '../../../../../../apps/suite/src/app/shared/access';
import { SvgIconComponent, SvgIcons } from '@ngneat/svg-icon';
import { RouterService } from '@suite/router';
import { FeatureFlagDirective } from '@logic-suite/shared/feature-flag/feature-flag.directive';

interface NavLink {
  path: string;
  tooltip: string;
  icon: SvgIcons;
}

@Component({
  selector: 'lib-rail',
  standalone: true,
  imports: [
    CommonModule,
    DividerModule,
    ButtonModule,
    AvatarModule,
    TooltipModule,
    RouterLink,
    SvgIconComponent,
    FeatureFlagDirective,
  ],
  templateUrl: './rail.component.html',
  styleUrl: './rail.component.scss',
})
export class RailComponent {
  routerService = inject(RouterService);
  customerService = inject(CustomerService);

  currentCustomer = toSignal(this.customerService.selectedCustomer$);

  selectCustomerTooltip = computed(() => {
    const currentCustomer = this.currentCustomer();
    if (!currentCustomer) {
      return 'Unknown customer';
    }

    return `${currentCustomer.customerID} - ${currentCustomer.name}`;
  });

  primaryNavLinks: NavLink[] = [
    { path: '/energy', tooltip: 'Energy', icon: 'energy' },
    { path: '/advise', tooltip: 'Advise', icon: 'bell' },
    { path: '/sense', tooltip: 'Sense', icon: 'chip' },
    { path: '/facility', tooltip: 'Facility', icon: 'flow' },
    { path: '/invoice', tooltip: 'Invoice', icon: 'money' },
  ];

  secondaryNavLinks: NavLink[] = [
    { path: '/config', tooltip: 'Configuration', icon: 'cog' },
    // { path: '/acl', tooltip: 'ACL', icon: 'acl' },
    { path: '/users', tooltip: 'User management', icon: 'users' },
  ];

  isActive(path: string): boolean {
    return this.routerService.currentUrl().startsWith(path) ?? false;
  }
}
