<aside class="flex flex-col items-center h-full w-16 justify-between py-4 bg-surface-900">
  <div>
    <!-- Noova logo -->
    <a class="rail-link" [routerLink]="['/energy']">
      <svg-icon key="noova-logo" size="xxl"></svg-icon>
    </a>

    <p-divider></p-divider>

    <!-- Customer select -->
    <!-- TODO(bjhandeland): Remove for users w/ only one customer -->
    <a class="rail-link"
       [ngClass]="{active: isActive('/select-customer')}"
       [routerLink]="['/select-customer']"
       pTooltip="{{selectCustomerTooltip()}}"
       [showDelay]="200">
      <svg-icon key="switch-arrows"></svg-icon>
    </a>

    <p-divider></p-divider>

    <!-- Primary nav -->
    <nav class="flex flex-col items-center gap-1">
      @for (link of primaryNavLinks; track link.path) {
        <a class="rail-link"
           [ngClass]="{active: isActive(link.path)}"
           [routerLink]="[link.path]"
           [queryParamsHandling]="'merge'"
           [pTooltip]="link.tooltip"
           [showDelay]="200">
          <svg-icon [key]="link.icon"></svg-icon>
        </a>
      }
    </nav>
  </div>

  <div>
    <!-- Secondary nav -->
    <nav class="flex flex-col items-center gap-1">
      @for (link of secondaryNavLinks; track link.path) {
        <a class="rail-link"
           [ngClass]="{active: isActive(link.path)}"
           [routerLink]="[link.path]"
           [queryParamsHandling]="'merge'"
           [pTooltip]="link.tooltip"
           [showDelay]="200">
          <svg-icon [key]="link.icon"></svg-icon>
        </a>
      }
    </nav>

    <p-divider></p-divider>

    <a class="rail-link"
       [ngClass]="{active: isActive('/profile')}"
       [routerLink]="['/profile']"
       pTooltip="Your profile"
       [showDelay]="200">
      <p-avatar shape="circle"></p-avatar>
    </a>
  </div>
</aside>
